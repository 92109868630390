import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence, useAnimation } from 'framer-motion';
import { TypeAnimation } from 'react-type-animation';
import { FaGithub, FaLinkedin, FaEnvelope, FaBook, FaGraduationCap, FaBriefcase, FaUser, FaTools } from 'react-icons/fa';
import { ParallaxProvider, Parallax } from 'react-scroll-parallax';
import { Canvas, useFrame } from '@react-three/fiber';
import { Points, PointMaterial } from '@react-three/drei';
import * as random from 'maath/random/dist/maath-random.esm';

const App = () => {
  const [currentSection, setCurrentSection] = useState('home');
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const sections = [
    { id: 'home', label: 'Home', icon: FaUser },
    { id: 'about', label: 'About', icon: FaUser },
    { id: 'experience', label: 'Experience', icon: FaBriefcase },
    { id: 'education', label: 'Education', icon: FaGraduationCap },
    { id: 'skills', label: 'Skills', icon: FaTools },
  ];

  return (
    <ParallaxProvider>
      <div className="bg-black text-white min-h-screen font-sans antialiased overflow-hidden">
        <StarField />
        <Navbar sections={sections} currentSection={currentSection} setIsMenuOpen={setIsMenuOpen} />
        <MobileMenu isOpen={isMenuOpen} setIsOpen={setIsMenuOpen} sections={sections} />
        <main>
          <Home setCurrentSection={setCurrentSection} />
          <About />
          <Experience />
          <Education />
          <Skills />
        </main>
        <SocialLinks />
      </div>
    </ParallaxProvider>
  );
};

const Navbar = ({ sections, currentSection, setIsMenuOpen }) => {
  return (
    <nav className="fixed top-0 left-0 right-0 z-50 bg-black/50 backdrop-blur-md">
      <div className="max-w-6xl mx-auto px-4">
        <div className="flex justify-between items-center h-16">
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
            className="text-xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-blue-100 to-purple-200"
          >
            HP
          </motion.div>
          
          {/* Desktop Menu */}
          <div className="hidden md:flex space-x-8">
            {sections.map((section) => (
              <motion.a
                key={section.id}
                href={`#${section.id}`}
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                className={`text-sm font-medium transition-colors duration-200 ${
                  currentSection === section.id ? 'text-purple-300' : 'text-gray-400 hover:text-purple-200'
                }`}
              >
                {section.label}
              </motion.a>
            ))}
          </div>

          {/* Mobile Menu Button */}
          <motion.button
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            className="md:hidden text-gray-400 hover:text-purple-200"
            onClick={() => setIsMenuOpen(true)}
          >
            <svg className="h-6 w-6" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
              <path d="M4 6h16M4 12h16M4 18h16"></path>
            </svg>
          </motion.button>
        </div>
      </div>
    </nav>
  );
};

const MobileMenu = ({ isOpen, setIsOpen, sections }) => {
  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0, x: '100%' }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: '100%' }}
          transition={{ type: 'tween' }}
          className="fixed inset-0 z-50 md:hidden"
        >
          <div className="absolute inset-0 bg-black/50 backdrop-blur-md" onClick={() => setIsOpen(false)} />
          <nav className="absolute right-0 top-0 bottom-0 w-64 bg-gray-900 shadow-xl">
            <div className="flex flex-col h-full">
              <div className="flex justify-end p-4">
                <button onClick={() => setIsOpen(false)} className="text-gray-400 hover:text-purple-200">
                  <svg className="h-6 w-6" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                    <path d="M6 18L18 6M6 6l12 12"></path>
                  </svg>
                </button>
              </div>
              <div className="flex-1 px-4 py-2 space-y-4">
                {sections.map((section) => (
                  <a
                    key={section.id}
                    href={`#${section.id}`}
                    className="block px-4 py-2 text-gray-400 hover:text-purple-200 transition-colors duration-200"
                    onClick={() => setIsOpen(false)}
                  >
                    <section.icon className="inline-block mr-2" />
                    {section.label}
                  </a>
                ))}
              </div>
            </div>
          </nav>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

// StarField component remains the same
const StarField = () => {
  return (
    <div className="fixed inset-0 z-0">
      <Canvas camera={{ position: [0, 0, 1] }}>
        <Stars />
      </Canvas>
    </div>
  );
};

// Stars component remains the same
function Stars(props) {
  const ref = React.useRef();
  const [sphere] = useState(() => random.inSphere(new Float32Array(5000), { radius: 1.5 }));

  useFrame((state, delta) => {
    ref.current.rotation.x -= delta / 30;
    ref.current.rotation.y -= delta / 45;
  });

  return (
    <group rotation={[0, 0, Math.PI / 4]}>
      <Points ref={ref} positions={sphere} stride={3} frustumCulled={false} {...props}>
        <PointMaterial
          transparent
          color="#ffffff"
          size={0.002}
          sizeAttenuation={true}
          depthWrite={false}
        />
      </Points>
    </group>
  );
}

const Home = ({ setCurrentSection }) => {
  useEffect(() => setCurrentSection('home'), [setCurrentSection]);
  const controls = useAnimation();

  useEffect(() => {
    controls.start({
      opacity: 1,
      y: 0,
      transition: { duration: 1, ease: "easeOut" }
    });
  }, [controls]);

  return (
    <section id="home" className="relative flex items-center justify-center h-screen overflow-hidden">
      <Parallax className="relative z-10 text-center" y={[-20, 20]} tagOuter="figure">
        <motion.h1
          initial={{ opacity: 0, y: -50 }}
          animate={controls}
          className="text-7xl font-bold mb-4 text-transparent bg-clip-text bg-gradient-to-r from-blue-100 via-purple-200 to-purple-300"
        >
          Hasan Pala
        </motion.h1>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.7, duration: 1 }}
        >
          <TypeAnimation
            sequence={[
              'Management Engineer',
              2000,
              'Strategic Thinker',
              2000,
              'Economics Enthusiast',
              2000,
            ]}
            wrapper="h2"
            repeat={Infinity}
            className="text-3xl text-gray-300 font-light"
          />
        </motion.div>
      </Parallax>
    </section>
  );
};

const About = () => {
  const controls = useAnimation();

  useEffect(() => {
    controls.start({
      opacity: 1,
      y: 0,
      transition: { duration: 1, ease: "easeOut" }
    });
  }, [controls]);

  return (
    <section id="about" className="relative z-10 min-h-screen flex items-center py-20 px-4">
      <div className="max-w-4xl mx-auto">
        <motion.h2
          initial={{ opacity: 0, y: 20 }}
          animate={controls}
          className="text-4xl font-bold mb-8 text-center text-transparent bg-clip-text bg-gradient-to-r from-blue-100 to-purple-200"
        >
          About Me
        </motion.h2>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={controls}
          className="bg-gray-900/50 rounded-lg p-8 backdrop-blur-sm"
        >
          <p className="text-gray-300 text-lg leading-relaxed mb-6">
            As a Management Engineer with a strong interest in strategic management, risk management, 
            insurance, and the Austrian School of Economics, I bring a unique blend of analytical, 
            strategic, and theoretical skills to any role in these fields.
          </p>
          <p className="text-gray-300 text-lg leading-relaxed">
            I am a lifelong learner who enjoys reading and debating about the latest developments 
            in my areas of interest, and I am eager to contribute my knowledge and passion to a 
            dynamic and innovative organization.
          </p>
        </motion.div>
      </div>
    </section>
  );
};

const Experience = () => {
  const controls = useAnimation();
  const experiences = [
    {
      company: "Corpus Sigorta",
      position: "Financial Affairs Assistant Specialist",
      period: "July 2024 - Present",
      location: "Istanbul, Turkey",
      description: "Specializing in financial affairs and risk management within the insurance sector."
    },
    {
      company: "Quick Sigorta",
      position: "Financial Affairs Intern",
      period: "May 2024 - July 2024",
      location: "Istanbul, Turkey",
      description: "Gained hands-on experience in financial operations and insurance processes."
    },
    {
      company: "Quick Sigorta",
      position: "Strategy and Business Development Intern",
      period: "February 2024 - May 2024",
      location: "Ataşehir, Istanbul",
      description: "Contributed to strategic planning and business development initiatives."
    },
    {
      company: "Penti",
      position: "Category Marketing Intern",
      period: "October 2022 - December 2022",
      location: "Istanbul, Turkey",
      description: "Focused on pricing strategies, competitor analysis, and decision-making processes."
    }
  ];

  useEffect(() => {
    controls.start(i => ({
      opacity: 1,
      y: 0,
      transition: { delay: i * 0.2, duration: 0.8, ease: "easeOut" }
    }));
  }, [controls]);

  return (
    <section id="experience" className="relative z-10 min-h-screen py-20 px-4">
      <div className="max-w-4xl mx-auto">
        <motion.h2
          initial={{ opacity: 0, y: 20 }}
          animate={controls}
          className="text-4xl font-bold mb-12 text-center text-transparent bg-clip-text bg-gradient-to-r from-blue-100 to-purple-200"
        >
          Professional Experience
        </motion.h2>
        <div className="space-y-8">
          {experiences.map((exp, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={controls}
              custom={index}
              className="bg-gray-900/50 rounded-lg p-6 backdrop-blur-sm hover:bg-gray-800/50 transition-colors duration-300"
            >
              <h3 className="text-xl font-semibold text-purple-200">{exp.position}</h3>
              <p className="text-gray-400 mt-2">{exp.company}</p>
              <p className="text-gray-500 text-sm mt-1">{exp.period}</p>
              <p className="text-gray-500 text-sm">{exp.location}</p>
              <p className="text-gray-400 mt-4">{exp.description}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

const Education = () => {
  const controls = useAnimation();
  const education = [
    {
      school: "Bahçeşehir Üniversitesi",
      degree: "Bachelor's degree, Management Engineering",
      period: "2020 - 2024",
      details: "Focused on management engineering principles, strategic planning, and analytical methodologies."
    },
    {
      school: "İzmir Özel Türk Koleji",
      degree: "High School Diploma",
      period: "2019",
      details: "Strong foundation in mathematics and sciences."
    }
  ];

  useEffect(() => {
    controls.start(i => ({
      opacity: 1,
      y: 0,
      transition: { delay: i * 0.2, duration: 0.8, ease: "easeOut" }
    }));
  }, [controls]);

  return (
    <section id="education" className="relative z-10 min-h-screen py-20 px-4">
      <div className="max-w-4xl mx-auto">
        <motion.h2
          initial={{ opacity: 0, y: 20 }}
          animate={controls}
          className="text-4xl font-bold mb-12 text-center text-transparent bg-clip-text bg-gradient-to-r from-blue-100 to-purple-200"
        >
          Education
        </motion.h2>
        <div className="space-y-8">
          {education.map((edu, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={controls}
              custom={index}
              className="bg-gray-900/50 rounded-lg p-6 backdrop-blur-sm hover:bg-gray-800/50 transition-colors duration-300"
              >
                <h3 className="text-xl font-semibold text-purple-200">{edu.school}</h3>
                <p className="text-gray-400 mt-2">{edu.degree}</p>
                <p className="text-gray-500 text-sm mt-1">{edu.period}</p>
                <p className="text-gray-400 mt-4">{edu.details}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>
    );
  };
  
  const Skills = () => {
    const controls = useAnimation();
    const skillCategories = [
      {
        title: "Technical Skills",
        skills: [
          { name: "Strategic Management", level: 90 },
          { name: "Risk Management", level: 85 },
          { name: "Financial Analysis", level: 88 },
          { name: "Insurance Operations", level: 85 },
        ]
      },
      {
        title: "Business Skills",
        skills: [
          { name: "Business Development", level: 85 },
          { name: "Market Analysis", level: 82 },
          { name: "Project Management", level: 80 },
          { name: "Decision Making", level: 85 },
        ]
      },
      {
        title: "Soft Skills",
        skills: [
          { name: "Analytical Thinking", level: 90 },
          { name: "Problem Solving", level: 88 },
          { name: "Communication", level: 85 },
          { name: "Teamwork", level: 85 },
        ]
      }
    ];
  
    useEffect(() => {
      controls.start(i => ({
        opacity: 1,
        y: 0,
        transition: { delay: i * 0.2, duration: 0.8, ease: "easeOut" }
      }));
    }, [controls]);
  
    return (
      <section id="skills" className="relative z-10 min-h-screen py-20 px-4">
        <div className="max-w-4xl mx-auto">
          <motion.h2
            initial={{ opacity: 0, y: 20 }}
            animate={controls}
            className="text-4xl font-bold mb-12 text-center text-transparent bg-clip-text bg-gradient-to-r from-blue-100 to-purple-200"
          >
            Skills & Expertise
          </motion.h2>
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
            {skillCategories.map((category, categoryIndex) => (
              <motion.div
                key={categoryIndex}
                initial={{ opacity: 0, y: 20 }}
                animate={controls}
                custom={categoryIndex}
                className="bg-gray-900/50 rounded-lg p-6 backdrop-blur-sm"
              >
                <h3 className="text-xl font-semibold text-purple-200 mb-6">{category.title}</h3>
                <div className="space-y-4">
                  {category.skills.map((skill, skillIndex) => (
                    <div key={skillIndex}>
                      <div className="flex justify-between mb-1">
                        <span className="text-gray-400">{skill.name}</span>
                        <span className="text-gray-500">{skill.level}%</span>
                      </div>
                      <div className="h-2 bg-gray-700 rounded-full">
                        <motion.div
                          initial={{ width: 0 }}
                          animate={{ width: `${skill.level}%` }}
                          transition={{ duration: 1, delay: 0.5 + (categoryIndex * 0.2 + skillIndex * 0.1) }}
                          className="h-full bg-gradient-to-r from-blue-300 to-purple-400 rounded-full"
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </section>
    );
  };
  
  const SocialLinks = () => {
    const controls = useAnimation();
  
    useEffect(() => {
      controls.start(i => ({
        opacity: 1,
        y: 0,
        transition: { delay: i * 0.3 + 1, duration: 1, ease: "easeOut" }
      }));
    }, [controls]);
  
    return (
      <motion.div 
        className="fixed bottom-10 left-0 right-0 flex justify-center items-center space-x-8 z-20"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 1, duration: 1, ease: "easeOut" }}
      >
        {[
          { icon: FaLinkedin, link: "https://www.linkedin.com/in/hasanpala/", title: "LinkedIn" },
          { icon: FaEnvelope, link: "mailto:hasanpala@protonmail.com", title: "Email" },
          { icon: FaBook, link: "https://mises.org", title: "Mises Institute" }
        ].map((social, index) => (
          <SocialIcon 
            key={index} 
            icon={social.icon} 
            link={social.link} 
            title={social.title}
            controls={controls} 
            custom={index} 
          />
        ))}
      </motion.div>
    );
  };
  
  const SocialIcon = ({ icon: Icon, link, title, controls, custom }) => (
    <motion.a
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      className="text-gray-400 hover:text-purple-400 transition-colors duration-200 group relative"
      whileHover={{ scale: 1.2, rotate: 5, transition: { duration: 0.3 } }}
      whileTap={{ scale: 0.9, transition: { duration: 0.3 } }}
      initial={{ opacity: 0, y: 20 }}
      animate={controls}
      custom={custom}
    >
      <Icon size={28} />
      <span className="absolute bottom-full left-1/2 transform -translate-x-1/2 -translate-y-2 bg-gray-900 text-white text-sm px-2 py-1 rounded opacity-0 group-hover:opacity-100 transition-opacity duration-200">
        {title}
      </span>
    </motion.a>
  );
  
  export default App;